.navbar {
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 9.5px 10px;
  z-index: 20;

  &__logo-wrapper {
    height: 30px;

    svg {
      width: 30px;
      height: 30px;
    }
  }

  &__buttons-wrapper {
    display: flex;
    align-items: center;
  }

  &__btn {
    padding: 6px 10px;
    border-radius: 15px;
    font-weight: bold;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #3a1960;
    cursor: pointer;

    svg {
      margin-right: 5px;
      width: 20px;
      height: 20px;
    }
  }

  &__new-button {
    color: #3a1960;
    background-color: #d9f5e1;
    border: 1px solid rgb(167, 210, 181);
    cursor: pointer;
    margin-right: 16px;
    display: inline-block;
    padding: 3.5px 16px;
    font-size: 14px;
    font-weight: 500;

    &:active {
      background-color: rgba(#d9f5e1, 0.8);
    }

    &:active {
      background-color: rgba(#d9f5e1, 0.7);
    }
  }

  .my-avatar {
    user-select: none;

    &:hover {
      opacity: 0.9;
    }

    &:active {
      opacity: 1;
    }
  }

  &__avatar-wrapper {
    position: relative;
  }

  &__dropdown {
    position: absolute;
    top: 50px;
    right: 0;
    width: 220px;
    border-radius: 8px;
    box-shadow: rgba(0, 0, 0, 0.16) 0 4px 10px 0;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    padding: 8px 0;

    &-btn {
      flex: 1;
      font-size: 16px;
      font-weight: 600;
      color: #241c15;
      padding: 10px;
      border: none;
      background-color: transparent;
      text-align: left;
      cursor: pointer;

      &:hover {
        background-color: rgb(243, 242, 241);
      }
    }
  }
}