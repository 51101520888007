
.side-navigation {
  overflow: hidden;
  z-index: 22;
  background-color: rgb(247, 249, 251);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  line-height: 1.5 !important;

  &__links {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 11px 0 16px 0;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &__bottom {
    &-btn-wrapper {
      padding: 0 15px;
      margin-bottom: 5px;
    }

    &-upgrade-btn {
      display: inline-block;
      background-color: #d8e7e9;
      color: #014e56;
      font-size: 14px;
      font-family: 'Open Sans', sans-serif;
      font-weight: bold;
      border-radius: 5px;
      width: 100%;
      text-align: center;
      padding: 12.5px 5px;
    }
  }

  &__user-info {
    display: flex;
    padding: 5px 15px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.18) 0.180969px -0.60323px 0.629791px -1.25px,
    rgba(0, 0, 0, 0.16) 0.687063px -2.29021px 2.39105px -2.5px,
    rgba(0, 0, 0, 0.06%) 3px -10px 10.4403px -3.75px;

    &-names {
      margin-left: 15px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &-name {
      color: #6c7177;
      font-size: 12px;
    }

    &-location-name {
      color: #000000;
      font-size: 12px;
    }
  }
}

.link-section {
  &__title {
    color: rgba(92, 92, 92, 0.8);
    font-weight: 500;
    font-size: 14px;
    padding: 5.5px 10px;
  }
}

.page-link__link {

  &:hover {
    background-color: rgb(243, 242, 241);
  }

  .link-icon, .link-right-icon {
    color: rgba(92, 92, 92, 0.8);
    display: inline-block;
  }

  .link-icon {
    height: 24px;
    width: 24px;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  .link-right-icon {
    width: 20px;
    height: 20px;

    svg {
      width: 20px;
      height: 20px;
    }
  }

  &.active {
    background-color: #459ccf;

    .link-text, .link-icon, .link-right-icon {
      color: #fff !important;
    }
  }
}

.location-switch {
  position: relative;
  padding: 18px 8px 8px 8px;

  &__button {
    border: 1px solid rgba(92, 92, 92, 0.2);
    background-color: transparent;
    border-radius: 5px;
    width: 100%;
    padding: 5px;
    height: 52px;
    outline: none;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    text-align: left;

    &-text-box {
      margin-left: 8px;
      flex: 1;
    }

    &-text-main {
      font-size: 14px;
      color: #363c42;
      font-weight: 500;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 165px;
      line-height: 1.5 !important;
    }

    &-text {
      font-size: 15px;
      color: #363c42;
      font-weight: 400;
      line-height: 1.5 !important;
    }

    svg {
      width: 20px;
      height: 20px;
      color: rgba(92, 92, 92, 0.8);
    }
  }
}

.location-switch-dropdown {
  position: absolute;
  top: 70px;
  right: 8px;
  left: 8px;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0 4px 10px 0;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  padding: 8px 0;

  &__btn {
    flex: 1;
    font-size: 16px;
    color: #241c15;
    padding: 10px;
    border: none;
    background-color: transparent;
    text-align: left;
    cursor: pointer;

    &:hover {
      background-color: rgb(243, 242, 241);
    }

    &.disabled {
      opacity: 0.6;
      cursor: not-allowed;

      &:hover {
        background-color: #fff;
      }
    }
  }
}
